<template>
  <div class="all-box">
    <div class="box">
      <div class="box-left">
        <div class="title" style="margin-bottom: 1vh;">会员Q&A</div>
        <div class="main">
          <div class="question">
            <span class="tilback">Q</span>
            <span class="back-detile">北京人艺之友联谊会是什么</span>
          </div>
          <div class="answer">
            <div class="tilanswer">A</div>
            <div class="answer-detile">
              北京人艺自建院以来，便始终同广大观众保持着密切的联系，社会对人艺的关注、良好的建议与要求，长期以来一直成为剧院艺术发展的重要组成部分。北京人艺之友联谊会通过组织相关的艺术联谊活动，旨在构架起一座观众相互沟通、相互交流的桥梁，以促进戏剧艺术的发展。
            </div>
          </div>
          <div class="question">
            <span class="tilback">Q</span>
            <span class="back-detile">如何注册成为会员？</span>
          </div>
          <div class="answer">
            <div class="tilanswer">A</div>
            <div class="answer-detile" style="line-height: 30px;">
              ● 会员申请条件
              <div>1.持本人有效证件(身份证/护照)均可申请成为人艺之友联谊会会员。</div>
              <div>2.会员申请表所列必填项目均须如实填写。</div>
              <div>3.年满18 周岁(含18 岁)，具有完全民事行为能力的公民。</div>
              <div>4.办理会员卡需交纳工本费 10元/卡。</div>
              <div>5.办卡日起三个工作日内，由票务中心工作人员激活后，方可使用。</div>
              <div>● 会员申请方式：</div>
              <div>申请人携带本人有效证件(身份证 /护照)到北京人艺票务中心申请办理会员卡。</div>
              <div>程序:票务中心前台领取——按要求填写申请表——将有效证件原件及申请表交前台工作人员——工作人员核实无误后，将申请表信息录入会员系统，方可使用。</div>
            </div>

          </div>
          <div class="question">
            <span class="tilback">Q</span>
            <span class="back-detile">会员有哪些权利与义务？</span>
          </div>
          <div class="answer">
            <div class="tilanswer">A</div>
            <div class="answer-detile" style="line-height: 30px;">
              ● 会员可享受如下权利：
              <div>1.持卡在北京人艺票务中心及北京人艺所属消费场所消费均可享受九折优惠(特例商品除外)。</div>
              <div>2.可通过微信公众号、微博等形式，获得北京人艺的最新演出资讯。</div>
              <div>3.经邀请，可优先参加北京人艺组织的相关戏剧联谊活动。</div>
              <div>● 会员应履行如下义务：</div>
              <div>1.应保证所提供的个人信息真实、完整、有效。</div>
              <div>2.自愿接受并自觉遵守会员手册所提出的各项规定和要求。</div>
              <div>3.自觉维护人艺和所属剧场的形象和声誉，不得恶意诋毁、损害人艺和所属剧场的形象和利益。</div>
            </div>
          </div>
          <div class="question">
            <span class="tilback">Q</span>
            <span class="back-detile">会员卡具体如何使用？</span>
          </div>
          <div class="answer">
            <div class="tilanswer">A</div>
            <div class="answer-detile" style="line-height: 30px;">
              1.会员卡实行实名制管理,只限会员本人使用。
              <div>2.会员购票(限购2-5张)、消费须提前出示会员卡，只限在北京人艺票务中心及北京人艺所属消费场所使用</div>
              <div>3.会员登录人艺官网(www.bjry.com)、“人艺票务中心”微信服务号选座购票，正确输入预留的手机号码登录，即可享受九折优惠。</div>
              <div>4.会员电话订票须告知会员卡号，取票时须出示会员卡。</div>
            </div>
          </div>
          <div class="question">
            <span class="tilback">Q</span>
            <span class="back-detile">会员积分如何获取与兑换？</span>
          </div>
          <div class="answer">
            <div class="tilanswer">A</div>
            <div class="answer-detile" style="line-height: 30px;">
              ● 积分的获取：
              <div>凡在北京人艺票务中心及北京人艺所属消费场所消费(特例商品除外)即可获得积分，积分原则为1元=1分。</div>
              <div>● 积分兑换细则：</div>
              <div>1.会员须持本人会员卡前往北京人艺票务中心兑换相应礼品。兑换成功后将从会员账户中扣减相应积分分值。</div>
              <div>2.积分兑换礼品及其他优惠定期更新，兑换活动均有有效期限，请会员务必在规定时间内及时前往北京人艺票务中心办理，逾期不予兑换。活动截止日，未经使用积分将给予保留，可在后续活动中一并兑换。</div>
              <div>3.兑换礼品数量有限，先兑先得，兑完为止。</div>
              <div>4.所兑换礼品若无质量问题，不予退换。</div>
              <div>5.卡内积分暂无累积时限，如若清空积分将另行通知。</div>
            </div>
          </div>
          <div class="question">
            <span class="tilback">Q</span>
            <span class="back-detile">会员卡如何挂失、补领和撤销？</span>
          </div>
          <div class="answer">
            <div class="tilanswer">A</div>
            <div class="answer-detile" style="line-height: 30px;">
              1.会员卡丢失须及时与北京人艺票务中心联系，核对相关登记信息后，申请挂失。
              <div>2.挂失后，会员本人须持申请时所提供的有效证件到北京人艺票务中心办理补领手续，原卡信息将转入新卡。（新卡收取工本费10元/张）</div>
              <div>3.主动撤销会员身份，须持会员卡、凭本人有效证件到北京人艺票务中心办理撤销手续，原卡信息将一同消除，卡片作废。</div>
            </div>
          </div>
          <div class="question">
            <span class="tilback">Q</span>
            <span class="back-detile">会员卡使用还有哪些注意事项？</span>
          </div>
          <div class="answer" style="margin-bottom: 1.0417vw;">
            <div class="tilanswer">A</div>
            <div class="answer-detile" style="line-height: 30px;">
              1. 会员卡为芯片卡，内存会员信息，请持卡人妥善保管，不得损毁或借他人使用。因严重损毁造成卡内信息丢失，将由会员本人承担一切损失。如发现非会员本人使用，票务中心有权拒绝使用。
              <div>2. 会员通讯信息（地址、电话等）如有变动，请及时主动通知票务中心进行更正。票务中心不承担因会员信息错误而未得到及时通知变更所导致的责任。</div>
              <div>3.
                会员所提供的信息务必真实、准确。通信地址请填写配送常用地址，未填写地址的会员将不安排配送服务。如发现所提供的信息为虚假信息，票务中心有权终止此卡持有者的会员资格，并追究相应的赔偿责任，持此卡会员不得再次申领。
              </div>
              <div>4. 北京人艺票务中心承诺对会员信息进行严格保密。会员如发现自己的信息被泄露，并能提供出确凿证据，票务中心将追究相关工作人员责任并承担给会员本人造成的损失。</div>
                <div>5. 如发现借助会员卡购票进行倒卖，票务中心有权单方面废除此卡，取消再次申请会员资格，并保留追究当事人责任的权利。</div>
              
            </div>
          </div>
        </div>


      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { getHomePortal } from "@/service/home";
export default {
  data() {
    return {
      rightList: [],
    };
  },
  created() {
    this.getRight();
  },
  methods: {
    check(item) {
      const { href } = this.$router.resolve({
        path: "/ArtsInformation/detail",
        query: {
          id: item.targetId,
          page: "1",
        },
      });
      window.open(href, "_blank");
    },
    async getRight() {
      const res = await getHomePortal();
      this.rightList = res.data.referralRight;
    },
  },
};
</script>

<style lang="less" scoped>
.all-box {
  .m-t1v {
    margin-top: 1vh;
  }

  .m-b1v {
    margin-bottom: 3vh;
  }

  margin-top: 0.8rem;

  .box {
    display: flex;

    .box-left {
      margin: 0 0.5rem;
      width: 100%;

      .title {
        font-size: 0.4rem;
        font-weight: 600;
      }

      .main {
        margin-left: 2.6042vw;
        margin-right: 2.6042vw;

        .question {
          margin-top: 6.8519vh;
          .back-detile {
            margin-left: 1.0417vw;
            font-family: 'Arial Negreta', 'Arial Normal', 'Arial', sans-serif;
            font-weight: 700;
            font-style: normal;
            font-size: 0.3238rem;

          }
        }

        .answer {
          display: flex;
          align-items: baseline; // 基线对齐
          margin-top: 1.8519vh;

          .tilanswer {
            background: #f9680d;
            width: 25px;
            height: 25px;
            line-height: 25px;
            font-size: 12px;
            border-radius: 50%;
            flex-shrink: 0;
          }

          .answer-detile {
            margin-left: 1.0417vw;
            font-size: 0.2938rem;
          }
        }

      }

      // 修改 .tilanswer 和 .tilback
      .tilanswer,
      .tilback {
        background: #f9680d; // .tilanswer 橙色
        width: 25px;
        height: 25px;
        color: #ffffff;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        border-radius: 50%;
        font-family: Arial, sans-serif;
        font-weight: bold;
        flex-shrink: 0; // 禁止 Flex 压缩
      }

      .tilback {
        background: #000; // .tilback 黑色
      }

      // 调整父容器
      .answer,
      .question {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 1.8519vh;
      }

      .answer-detile,
      .back-detile {
        font-family: Arial, sans-serif;
        font-size: 14px; // 固定字体大小
        margin-left: 0; // 用 gap 替代 margin
      
      }


    }


  }
}
</style>