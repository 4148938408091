<template>
  <div class="fixed-page">
    <div class="concatBox">
      <div class="content">
        <Breadcrumb class="breadcrumb-1400" />
        <div class="foun-box">
          <div class="left">
            <div class="title">北京人民艺术剧院</div>
            <div class="map_box">
              <img src="@/assets/img/concat_map.png" alt="" />
            </div>
            <div class="title">首都剧场 & 北京人艺实验剧场 & 曹禺剧场 & 人艺小剧场</div>
            <div class="content_item">
              <div class="content_title">地址：</div>
              <div class="content_text">北京市-东城区-王府井大街-22号</div>
            </div>
            <div class="content_item">
              <div class="content_title">邮编：</div>
              <div class="content_text">100006</div>
            </div>
            <div class="content_item">
              <div class="content_title">邮箱：</div>
              <div class="content_text">bjrenyi@126.com</div>
            </div>
            <div class="content_item">
              <div class="content_title">乘车路线：</div>
            </div>
            <div class="content-traffic">
              <div class="traffic-title">
                <div>
                  ■&nbsp;
                </div>
                <div>
                  公交
                </div>：
              </div>
              <div class="traffic-content">
                <div>灯市西口站：104路、108路</div>
                <div> 美术馆东站：58路、101路、109路、128路</div>
              </div>
            </div>
            <div class="content-traffic">
              <div class="traffic-title">
                <div>
                  ■&nbsp;
                </div>
                <div>
                  地铁
                </div>：
              </div>
              <div class="traffic-content">
                <div>5号线：东四站（D出口）、6号线：东四站（G出口），步行向西至中国美术馆路

                  口向南约200米即到。</div>
                <div> 8号线：中国美术馆站（B出口），步行向南约200米即到。</div>
              </div>
            </div>
            <div class="title">
              菊隐剧场
            </div>
            <div class="content_item">
              <div class="content_title">地址：</div>
              <div class="content_text">北京市东城区东四南大街143号</div>
            </div>
            <div class="content_item">
              <div class="content_title">乘车路线：</div>
            </div>
            <div class="content-traffic">
              <div class="traffic-title">
                <div>
                  ■&nbsp;
                </div>
                <div>
                  公交
                </div>：
              </div>
              <div class="traffic-content">
                灯市东口站：104路、108路、110路、111路
              </div>
            </div>
            <div class="content-traffic">
              <div class="traffic-title">
                <div>
                  ■&nbsp;
                </div>
                <div>
                  地铁
                </div>：
              </div>
              <div class="traffic-content">
                5号线灯市口站（A出口），步行向北即到。
              </div>
            </div>
            <div class="title">
              综合联系<span>（负责对外公务、媒体接洽等工作）</span>
            </div>
            <div class="content_item">
              <div class="content_title">对外公务：</div>
              <div class="content_text">办公室 010-65135801</div>
            </div>
            <div class="content_item">
              <div class="content_title">媒体联络：</div>
              <div class="content_text">宣传策划处 010-65246789 转 3102</div>
            </div>
            <div class="content_item">
              <div class="content_title"> 传真：</div>
              <div class="content_text">010-65139770</div>
            </div>
            <div class="title">
              观众服务<span>（负责个人和团体购票的相关事宜，提供会员信息查询、会员权益解释、
会员积分兑换等会员专属服务与参观博物馆咨询接洽工作）</span>
            </div>
            <div class="content_item">
              <div class="content_title">购票：</div>
              <div class="content_text">演出中心 010-65250996、010-65249847</div>
            </div>
            <div class="content_item">
              <div class="content_title"> 会员服务：</div>
              <div class="content_text">演出中心 010-65250123</div>
            </div>
            <div class="content_item">
              <div class="content_title"> 戏剧博物馆团体参观咨询：</div>
              <div class="content_text">戏剧博物馆 010-85120006</div>
            </div>




<!-- 
            <div class="title">
              一站式服务热线<span>（负责对外公务、媒体接洽、问题解答等工作）</span>
            </div>
            <div class="content_item">
              <div class="content_title">联系电话：</div>
              <div class="content_text">010-65246789</div>
            </div>
            <div class="content_item">
              <div class="content_title">传真：</div>
              <div class="content_text">010-65139770</div>
            </div>
            <div class="content_item">
              <div class="content_title">对外公务联系接洽：</div>
              <div class="content_text">办公室 010-65139770</div>
            </div>
            <div class="content_item" style="margin-bottom: 7.4074vh">
              <div class="content_title">媒体联络接洽热线：</div>
              <div class="content_text">宣传策划处&nbsp;010-65246789 转 3102</div>
            </div>
            <div class="title">
              观众服务中心<span>（负责个人和团体购票的相关事宜，提供会员信息查询、会员权益解释、会员积分兑换等会员专属服务与参观博物馆咨询接洽工作）</span>
            </div>
            <div class="content_item">
              <div class="content_title">购票、会员咨询热线：</div>
              <div class="content_text">
                演出中心 010-65250996、010-65249847、010-65250123
              </div>
            </div>
            <div class="content_item" style="padding-bottom: 6.4815vh">
              <div class="content_title">戏剧博物馆团体参观咨询热线：</div>
              <div class="content_text">戏剧博物馆 010-85120006</div>
            </div> -->
          </div>
          <div class="right">
            <div class="title">热点资讯</div>
            <div @click="check(item)" v-for="item in this.rightList" :key="item.id" class="content">
              <div class="name">{{ item.name }}</div>
              <div class="time">{{ "发布时间：" + item.targetCreateTime }}</div>
            </div>
          </div>
        </div>
        <PageFooter />
      </div>
    </div>
  </div>
</template>

<script>
import { getHomePortal } from "@/service/home";
export default {
  data() {
    return {
      rightList: [],
    };
  },
  created() {
    this.getRight();
  },
  methods: {
    check(item) {
      const { href } = this.$router.resolve({
        path: "/ArtsInformation/detail",
        query: {
          id: item.targetId,
          page: "1",
        },
      });
      window.open(href, "_blank");
    },
    async getRight() {
      const res = await getHomePortal();
      this.rightList = res.data.referralRight;
    },
  },
};
</script>

<style lang="less" scoped>
.concatBox {
  .breadcrumb-title {
    margin-left: 2.0833vw;
  }

  .foun-box {
    display: flex;
    // width: 1800px;
    margin: 2.0833vw 13.0208vw 2.6042vw 13.0208vw;
    min-height: 52.0833vw;
    height: auto;
    display: flex;
    position: relative;
    /* 添加定位属性 */
    z-index: 5;

    .left {
      width: 52.0833vw;
      background-color: #ffffff;

      .title {
        font-weight: 700;
        font-size: 1.4583vw;
        text-align: left;
        margin-top: 1.8229vw;
        margin-left: 2.6042vw;
        margin-right: 2.6042vw;
        margin-bottom: 1.5625vw;

        span {
          font-size: 1.0417vw;
          font-weight: normal;
        }
      }

      .map_box {
        margin-top: 1.8229vw;
        margin-left: 2.6042vw;
        margin-right: 2.6042vw;
        margin-bottom: 2.6042vw;
        width: 24.4792vw;
        height: 22.2917vw;
        object-fit: contain;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .content_item {
        display: flex;
        align-items: center;
        margin-bottom: 1.3021vw;
        margin-left: 2.6042vw;

        .content_title {
          font-size: 1.0417vw;
          font-weight: 600;
        }

        .content_text {
          font-size: 1.0417vw;
        }
      }

      .content-traffic {
        margin-left: 2.6042vw;

        .traffic-title {
          display: flex;
          font-size: 1.0417vw;
          line-height: 4.1667vh;
          font-family: 'Arial Normal', 'Arial', sans-serif;
          font-weight: 400;
        }

        .traffic-content {
          margin-left: 3.6042vw;
          font-size: 1.0417vw;
          line-height: 4.1667vh;
        }
      }
    }

    .right {
      width: 20.8333vw;
      margin-left: 2.6042vw;
      height: 26.0417vw;
      background-color: #ffffff;

      .title {
        text-align: left;
        margin-top: 1.3021vw;
        margin-left: 1.3021vw;
        font-size: 0.8333vw;
        font-weight: 700;
      }

      .content {
        margin-top: 1.3021vw;
        margin-left: 1.3021vw;
        margin-right: 1.3021vw;
        text-align: justify;
        cursor: pointer;
        padding-bottom: 1.3889vh;
        box-sizing: border-box;
        border-bottom: 1px solid #ccc;

        .name {
          font-weight: 700;
          font-size: 0.8333vw;
        }

        .time {
          position: relative;
          margin-top: 0.5208vw;
          font-size: .6771vw;
          color: #666;

          &+.time {
            margin-top: 1.5625vw;
          }

          &::after {
            content: "";
            width: 0.8333vw;
            height: 0.0521vw;
            background-color: #999999;
            position: absolute;
            right: 0;
            top: 0.2604vw;
          }

          &::before {
            content: "";
            width: 0.2604vw;
            height: 0.2604vw;
            border: 0.0521vw solid transparent;
            border-right-color: #999999;
            border-bottom-color: #999999;
            transform: rotate(-45deg);
            position: absolute;
            right: 0;
            top: 0.1042vw;
          }
        }
      }

      .content:last-child {
        border-bottom: none;
      }
    }
  }
}
</style>