<template>
  <div class="all-box">
    <div class="box">
      <div class="box-left">
        <div class="title" style="margin-bottom: 1vh;">北京人民艺术剧院</div>
        <div class="map_box">
              <img src="@/assets/img/concat_map.png" alt="" />
            </div>
        <div class="titles">首都剧场 & 北京人艺实验剧场 & 曹禺剧场 & 人艺小剧场</div>
        <div class="content_item">
          <div class="content_title">地址：</div>
          <div class="content_text">北京市-东城区-王府井大街-22号</div>
        </div>
        <div class="content_item">
          <div class="content_title">邮编：</div>
          <div class="content_text">100006</div>
        </div>
        <div class="content_item">
          <div class="content_title">邮箱：</div>
          <div class="content_text">bjrenyi@126.com</div>
        </div>
        <div class="content_item">
          <div class="content_title">乘车路线：</div>
        </div>
        <div class="content-traffic">
          <div class="traffic-title">
            <div>
              ■&nbsp;
            </div>
            <div>
              公交
            </div>：
          </div>
          <div class="traffic-content">
            <div>灯市西口站：104路、108路</div>
            <div> 美术馆东站：58路、101路、109路、128路</div>
          </div>
        </div>
        <div class="content-traffic">
              <div class="traffic-title">
                <div>
                  ■&nbsp;
                </div>
                <div>
                  地铁
                </div>：
              </div>
              <div class="traffic-content">
                <div>5号线：东四站（D出口）、6号线：东四站（G出口），步行向西至中国美术馆路

                  口向南约200米即到。</div>
                <div> 8号线：中国美术馆站（B出口），步行向南约200米即到。</div>
              </div>
            </div>
            <div class="titles">
              菊隐剧场
            </div>
            <div class="content_item">
              <div class="content_title">地址：</div>
              <div class="content_text">北京市东城区东四南大街143号</div>
            </div>
            <div class="content_item">
              <div class="content_title">乘车路线：</div>
            </div>
            <div class="content-traffic">
              <div class="traffic-title">
                <div>
                  ■&nbsp;
                </div>
                <div>
                  公交
                </div>：
              </div>
              <div class="traffic-content">
                灯市东口站：104路、108路、110路、111路
              </div>
            </div>
            <div class="content-traffic">
              <div class="traffic-title">
                <div>
                  ■&nbsp;
                </div>
                <div>
                  地铁
                </div>：
              </div>
              <div class="traffic-content">
                5号线灯市口站（A出口），步行向北即到。
              </div>
            </div>
            <div class="titles">
              综合联系<span class="tildeils">（负责对外公务、媒体接洽等工作）</span>
            </div>
            <div class="content_item">
              <div class="content_title">对外公务：</div>
              <div class="content_text">办公室 010-65135801</div>
            </div>
            <div class="content_item">
              <div class="content_title">媒体联络：</div>
              <div class="content_text">宣传策划处 010-65246789 转 3102</div>
            </div>
            <div class="content_item">
              <div class="content_title"> 传真：</div>
              <div class="content_text">010-65139770</div>
            </div>
            <div class="titles m-t1v" >
              观众服务<span class="tildeils">（负责个人和团体购票的相关事宜，提供会员信息查询、会员权益解释、
会员积分兑换等会员专属服务与参观博物馆咨询接洽工作）</span>
            </div>
            <div class="content_item">
              <div class="content_title">购票：</div>
              <div class="content_text">演出中心 010-65250996、010-65249847</div>
            </div>
            <div class="content_item">
              <div class="content_title"> 会员服务：</div>
              <div class="content_text">演出中心 010-65250123</div>
            </div>
            <div class="content_item m-b1v">
              <div class="content_title"> 戏剧博物馆团体参观咨询：</div>
              <div class="content_text">戏剧博物馆 010-85120006</div>
            </div>

      </div>
    </div>
    <PageFooter />
  </div>
</template>

<script>
import { getHomePortal } from "@/service/home";
export default {
  data() {
    return {
      rightList: [],
    };
  },
  created() {
    this.getRight();
  },
  methods: {
    check(item) {
      const { href } = this.$router.resolve({
        path: "/ArtsInformation/detail",
        query: {
          id: item.targetId,
          page: "1",
        },
      });
      window.open(href, "_blank");
    },
    async getRight() {
      const res = await getHomePortal();
      this.rightList = res.data.referralRight;
    },
  },
};
</script>

<style lang="less" scoped>
.all-box {
  .m-t1v{
    margin-top: 1vh;
  }
  .m-b1v{
    margin-bottom: 3vh;
  }
  margin-top: 0.8rem;
  .map_box {
    margin-top: 4.8229vw;
    // margin-left: 2.6042vw;
    margin-right: 2.6042vw;
    // margin-bottom: 4.6042vw;
        width: 71.4792vw;
        height: 54.2917vw;
        object-fit: contain;

        img {
          width: 100%;
          height: 100%;
        }
      }
  .box {
    display: flex;

    .box-left {
      margin: 0 0.5rem;
      width: 100%;

      .title {
        font-size: 0.4rem;
        font-weight: 600;
      }
      .titles{
        // font-size: 0.32rem;
        font-size: 3.5vw;
        font-weight: 600;
        margin-top: 4.6042vw;
        .tildeils{
          font-size: 0.3rem;
          font-weight: 400;
        }
      }
      
      

    }

    .content_item {
      display: flex;
      align-items: center;
      // margin-bottom: 1.3021vw;
      margin-top: 2.3021vw;
      // margin-left: 2.6042vw;

      .content_title {
        font-size: 25/75rem;
        font-weight: 600;
      }

      .content_text {
        font-size: 25/75rem;
        // font-size: 1.0417vw;
      }
    }
    .content-traffic {
        margin-left: 2.6042vw;

        .traffic-title {
          display: flex;
          font-size: 25/75rem;
          line-height: 4.1667vh;
          font-family: 'Arial Normal', 'Arial', sans-serif;
          font-weight: 400;
        }

        .traffic-content {
          margin-left: 3.6042vw;
          font-size: 25/75rem;
          line-height: 4.1667vh;
        }
      }
  }
}
</style>